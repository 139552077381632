import { Button } from "@mantine/core";
import { useContext } from "react";
import { AuthContext } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";

export default function MainLayout({pageComponent}: {pageComponent: React.ReactNode;}) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const logOutClicked = () => {
    authContext.signOut();
    navigate("/");
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "#E4E4E4",
        paddingLeft: "40px",
        paddingRight: "40px",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "1200px",
          display: "block",
          paddingTop: "32px",
          paddingBottom: "32px",
          height: "fit-content",
        }}
      >
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <div
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#228be6",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "20px",
            }}
          >
            M
          </div>
          <div>
            <Button radius="md" onClick={() => logOutClicked()}>Logout</Button>
          </div>
        </div>
        <div style={{ paddingTop: "24px" }}>
          {pageComponent}
        </div>
      </div>
    </div>
  );
}
