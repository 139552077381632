/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useContext } from "react";
import { Table, Select, Button } from "@mantine/core";
import userServices from "../../services/userServices";
import MainLayout from "../../layouts/mainlayouts";
import MenuBar from "../../components/menuBar";
import { Loader } from '@mantine/core';
import { IActivity } from "../../models";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { toastError } from "../../utils/toasts";

export default function Activity() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate()
  const [selected, setSelected] = useState("Activity");
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [activityDetail, setActivityDetail] = useState<IActivity[]>([])
  const [pageLimit, setPageLimit] = useState("10")
  const [page, setPage] = useState(1)
  const [pageNextStatus, setPageNextStatus] = useState(false)
  const [pageBeforeStatus, setPageBeforeStatus] = useState(false)

  const pageNext = () => {
    setPage(page + 1)
  }

  const pageBefore = () => {
    setPage(page - 1)
  }

  const getActivityDetails = async () => {
    try {
      setErrorMessage("")
      setLoading(true)
      const activity = await userServices.getActivity(page, parseInt(pageLimit));
      if (activity.status === 200) {
        const tmpAct: IActivity[] = []
        activity?.data.map((_item: any, index: number) => {
          const tmp: IActivity = {
            id: _item.id,
            user: _item.userId,
            client: _item.clientName ? _item.clientName : "N/A",
            activitytime: _item.updated_at ? _item.updated_at.split(".")[0] : "",
            activitydescription: _item.activity
          }
          tmpAct.push(tmp)
          return
        })
        setActivityDetail(tmpAct)
        setLoading(false)
      } else if (activity.status === 403) {
        authContext.signOut();
        navigate("/");
      } else {
        setLoading(false)
        toastError(activity.data.error)
      }
    } catch (err: any) {
      setErrorMessage(err)
      setLoading(false)
    }
  };

  useEffect(() => {
    getActivityDetails()
  }, [pageLimit, page]);

  useEffect(() => {
    if (activityDetail.length === parseInt(pageLimit)) {
      setPageNextStatus(true)
    } else {
      setPageNextStatus(false)
    }

    if (page > 1) {
      setPageBeforeStatus(true)
    } else {
      setPageBeforeStatus(false)
    }
  }, [pageLimit, page, activityDetail])

  const rows = activityDetail?.map((element: IActivity, index: number) => (
    <tr key={element.id}>
      <td>{element.user}</td>
      <td>{element.client}</td>
      <td>{element.activitytime}</td>
      <td>{element.activitydescription}</td>
    </tr>
  ));

  return (
    <MainLayout
      pageComponent={
        !loading ? 
        <>
          <MenuBar selected={selected} setSelected={setSelected} />
          <div 
            style={{
              display: "block",
              width: "100%",
              backgroundColor: "#FFFFFF",
              minHeight: "100vh",
              borderRadius: "10px",
              boxShadow: "2",
              position: "relative"
            }}
          >
            <div style={{
              position: "absolute",
              right: "16px",
              top: "16px",
              width: "120px"
            }}>
              <Select
                placeholder="Pick value"
                data={["5", "10", "20", "30"]}
                value={pageLimit}
                onChange={(event: any) => setPageLimit(event)}
              />
            </div>
            <>
              {activityDetail?.length > 0 ? (
                <div
                  style={{
                    marginTop: "30px",
                    width: "100%",
                    padding: "20px"
                  }}
                >
                  <Table striped highlightOnHover withBorder style={{marginTop: "50px"}}>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Client</th>
                        <th>Activity Timestamp (UTC)</th>
                        <th>Activity Description</th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </Table>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      marginTop: "50px",
                      width: "100%",
                      padding: "20px"
                    }}
                  >
                    <Table striped highlightOnHover withBorder style={{marginTop: "50px"}}>
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Firstname</th>
                          <th>Lastname</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                    </Table>
                  </div>
                </>)
              }
            </>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px"
            }}>
              <div>
                <Button 
                  variant="outline"
                  disabled={!pageBeforeStatus}
                  style={{padding: "5px"}}
                  onClick={() => pageBefore()}
                >
                  <IconChevronLeft/>
                </Button>
              </div>
              <div>{page}</div>
              <div>
                <Button
                  variant="outline"
                  disabled={!pageNextStatus}
                  style={{padding: "5px"}}
                  onClick={() => pageNext()}
                >
                  <IconChevronRight/>
                </Button>
              </div>
            </div>
          </div>
        </>
        :
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: '100%'}}><Loader size={50} /></div>
      }
    />
  );
}
