import { useState, useEffect, useContext } from "react";
import { Select, Button, Tabs } from "@mantine/core";
import userServices from "../../services/userServices";
import MainLayout from "../../layouts/mainlayouts";
import MenuBar from "../../components/menuBar";
import { Loader } from '@mantine/core';
import { IPromotions } from "../../models";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { toastError } from "../../utils/toasts";
import PromotionTable from "../../components/promotion/PromotionTable";

export default function Promotions() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate()
  const [selected, setSelected] = useState("Promotions");
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [pendingPromotionsDetail, setPendingPromotionsDetail] = useState<IPromotions[]>([])
  const [promotionsDetail, setPromotionsDetail] = useState<IPromotions[]>([])
  const [pageLimit, setPageLimit] = useState("10")
  const [pagePending, setPagePending] = useState(1)
  const [pageAll, setPageAll] = useState(1)
  const [pageNextStatus, setPageNextStatus] = useState(false)
  const [pageBeforeStatus, setPageBeforeStatus] = useState(false)

  const pageNext = (tab: number) => {
    if (tab === 1) {
      setPagePending(pagePending + 1)
    } else {
      setPageAll(pageAll + 1)
    }
  }

  const pageBefore = (tab: number) => {
    if (tab === 1) {
      setPagePending(pagePending - 1)
    } else {
      setPageAll(pageAll - 1)
    }
  }

  const getPendingPromotionsDetails = async () => {
    try {
      setErrorMessage("")
      setLoading(true)
      const promotions = await userServices.getPendingPromotions(pagePending, parseInt(pageLimit));
      if (promotions.status === 200) {
        const tmpPromotions: IPromotions[] = []
        promotions?.data.map((_item: any, index: number) => {
          const tmp: IPromotions = {
            id: _item.id,
            userId: _item.userId,
            clientId: _item.clientId,
            clientName: _item.clientName,
            sourceEnvironmentName: _item.sourceEnvironmentName,
            targetEnvironmentName: _item.targetEnvironmentName,
            status: _item.status,
            message: _item.message ? _item.message : "",
            history: _item.history ? _item.history : "",
            created_at: _item.created_at ? _item.created_at.split(".")[0] : "",
            prLink: _item.prLink,
            promotionTitle: _item.promotionTitle ? _item.promotionTitle : "",
            promotionDescription: _item.promotionDescription ? _item.promotionDescription : ""
          }
          tmpPromotions.push(tmp)
          return
        })
        setPendingPromotionsDetail(tmpPromotions)
        setLoading(false)
      } else if (promotions.status === 403) {
        authContext.signOut();
        navigate("/");
      } else {
        setLoading(false)
        toastError(promotions.data.error)
      }
    } catch (err: any) {
      setErrorMessage(err)
      setLoading(false)
      toastError("Something went wrong. Please refresh the page and try again")
    }
  };

  const getPromotionsDetails = async () => {
    try {
      setErrorMessage("")
      setLoading(true)
      const promotions = await userServices.getAllPromotions(pageAll, parseInt(pageLimit));
      if (promotions.status === 200) {
        const tmpPromotions: IPromotions[] = []
        promotions?.data.map((_item: any, index: number) => {
          const tmp: IPromotions = {
            id: _item.id,
            userId: _item.userId,
            clientId: _item.clientId,
            clientName: _item.clientName,
            sourceEnvironmentName: _item.sourceEnvironmentName,
            targetEnvironmentName: _item.targetEnvironmentName,
            status: _item.status,
            message: _item.message ? _item.message : "",
            history: _item.history ? _item.history : "",
            created_at: _item.created_at ? _item.created_at.split(".")[0] : "",
            prLink: _item.prLink,
            promotionTitle: _item.promotionTitle ? _item.promotionTitle : "",
            promotionDescription: _item.promotionDescription ? _item.promotionDescription : ""
          }
          tmpPromotions.push(tmp)
          return
        })
        setPromotionsDetail(tmpPromotions)
        setLoading(false)
      } else if (promotions.status === 403) {
        authContext.signOut();
        navigate("/");
      } else {
        setLoading(false)
        toastError(promotions.data.error)
      }
    } catch (err: any) {
      setErrorMessage(err)
      setLoading(false)
      toastError("Something went wrong. Please refresh the page and try again")
    }
  };

  useEffect(() => {
    getPendingPromotionsDetails()
  }, [pageLimit, pagePending]);

  useEffect(() => {
    getPromotionsDetails()
  }, [pageLimit, pageAll]);

  useEffect(() => {
    if (pendingPromotionsDetail.length === parseInt(pageLimit)) {
      setPageNextStatus(true)
    } else {
      setPageNextStatus(false)
    }

    if (pagePending > 1) {
      setPageBeforeStatus(true)
    } else {
      setPageBeforeStatus(false)
    }
  }, [pageLimit, pagePending, pendingPromotionsDetail])

  useEffect(() => {
    if (promotionsDetail.length === parseInt(pageLimit)) {
      setPageNextStatus(true)
    } else {
      setPageNextStatus(false)
    }

    if (pageAll > 1) {
      setPageBeforeStatus(true)
    } else {
      setPageBeforeStatus(false)
    }
  }, [pageLimit, pageAll, promotionsDetail])

  return (
    <MainLayout
      pageComponent={
        !loading ?
          <>
            <MenuBar selected={selected} setSelected={setSelected} />
            <div
              style={{
                display: "block",
                width: "100%",
                backgroundColor: "#FFFFFF",
                minHeight: "100vh",
                borderRadius: "10px",
                boxShadow: "2",
                position: "relative"
              }}
            >
              <Tabs color="teal" defaultValue="first" pt={"24px"} mt={"40px"}>
                <Tabs.List>
                  <Tabs.Tab value="first">Pending Deployment</Tabs.Tab>
                  <Tabs.Tab value="second">
                    All Promotions
                  </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="first" pt="xs">
                  <>
                    <div style={{
                      position: "absolute",
                      right: "16px",
                      top: "16px",
                      width: "120px"
                    }}>
                      <Select
                        placeholder="Pick value"
                        data={["5", "10", "20", "30"]}
                        value={pageLimit}
                        onChange={(event: any) => setPageLimit(event)}
                      />
                    </div>
                    <PromotionTable promotions={pendingPromotionsDetail} />  
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "16px"
                    }}>
                      <div>
                        <Button
                          variant="outline"
                          disabled={!pageBeforeStatus}
                          style={{ padding: "5px" }}
                          onClick={() => pageBefore(1)}
                        >
                          <IconChevronLeft />
                        </Button>
                      </div>
                      <div>{pagePending}</div>
                      <div>
                        <Button
                          variant="outline"
                          disabled={!pageNextStatus}
                          style={{ padding: "5px" }}
                          onClick={() => pageNext(1)}
                        >
                          <IconChevronRight />
                        </Button>
                      </div>
                    </div>
                  </>
                </Tabs.Panel>
                <Tabs.Panel value="second" pt="xs">
                  <>
                    <div style={{
                      position: "absolute",
                      right: "16px",
                      top: "16px",
                      width: "120px"
                    }}>
                      <Select
                        placeholder="Pick value"
                        data={["5", "10", "20", "30"]}
                        value={pageLimit}
                        onChange={(event: any) => setPageLimit(event)}
                      />
                    </div>
                    <PromotionTable promotions={promotionsDetail} />
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "16px"
                    }}>
                      <div>
                        <Button
                          variant="outline"
                          disabled={!pageBeforeStatus}
                          style={{ padding: "5px" }}
                          onClick={() => pageBefore(2)}
                        >
                          <IconChevronLeft />
                        </Button>
                      </div>
                      <div>{pageAll}</div>
                      <div>
                        <Button
                          variant="outline"
                          disabled={!pageNextStatus}
                          style={{ padding: "5px" }}
                          onClick={() => pageNext(2)}
                        >
                          <IconChevronRight />
                        </Button>
                      </div>
                    </div>
                  </>
                </Tabs.Panel>
              </Tabs>
            </div>
          </>
          :
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: '100%' }}><Loader size={50} /></div>
      }
    />
  );
}
