import { TextInput, Button, PasswordInput } from "@mantine/core";
import { IUsersDetail } from "../../models";
import { useState, useEffect } from "react";
import userServices from "../../services/userServices";
import { toastSuccess, toastError } from "../../utils/toasts";

export default function CreateUser(args: IUsersDetail) {
  const { userDetail, setUserDetail, setUserAdd } = args;
  const [enabled, setEnabled] = useState(false)

  const createUserHandle = async () => {
    try {
      const userCreation = await userServices.createUser(
        userDetail.username,
        userDetail.password,
        userDetail.firstname,
        userDetail.lastname,
        userDetail.email,
        true
      )
      if (userCreation.status === 200) {
        setUserAdd(false)
        toastSuccess('User created successfully.')
      } else {
        setUserAdd(true)
        toastError(userCreation.data.result)
      }
    } catch(error: any) {
      setUserAdd(true)
      toastError('User creation failed. Please try again with correct info.')
    }
  }

  useEffect(() => {
    setEnabled(
      userDetail.username !== "" &&
      userDetail.firstname !== "" &&
      userDetail.lastname !== "" &&
      userDetail.email !== "" &&
      userDetail.password !== "" &&
      userDetail.password === userDetail.passwordConfirm
    )
  }, [userDetail])

  return (
    <div style={{ width: "36%" }}>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <TextInput
          label="Username"
          placeholder="Username"
          value={userDetail.username}
          onChange={(event: any) => setUserDetail({...userDetail, username: event.currentTarget.value})}
        />
      </div>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <TextInput
          label="First Name"
          placeholder="Firstname"
          value={userDetail.firstname}
          onChange={(event: any) => setUserDetail({...userDetail, firstname: event.currentTarget.value})}
        />
      </div>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <TextInput
          label="Last Name"
          placeholder="Lastname"
          value={userDetail.lastname}
          onChange={(event: any) => setUserDetail({...userDetail, lastname: event.currentTarget.value})}
        />
      </div>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <TextInput
          label="Email Address"
          placeholder="Email address"
          value={userDetail.email}
          onChange={(event: any) => setUserDetail({...userDetail, email: event.currentTarget.value})}
        />
      </div>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <PasswordInput
          value={userDetail.password}
          onChange={(event: any) => setUserDetail({...userDetail, password: event.currentTarget.value})}
          label="Password"
          placeholder="Password"
          required
        />
      </div>
      <div
        style={{
          marginTop: "8px",
        }}
      >
        <PasswordInput
          value={userDetail.passwordConfirm}
          onChange={(event: any) => setUserDetail({...userDetail, passwordConfirm: event.currentTarget.value})}
          placeholder="Confirm Password"
          required
        />
      </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px"
      }}>
        <Button
          onClick={() => createUserHandle()}
          disabled={!enabled}
        >
          Create User
        </Button>
      </div>
    </div>
  );
}
