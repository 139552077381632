import { Select } from "@mantine/core";
import { useState, useEffect } from "react";
import userServices from "../../services/userServices";
import { IRepositoryDetail, IRepository } from "../../models";

export default function RepositoryDetail(args: IRepositoryDetail) {
  const { repository, setRepository, gitServiceDetail, setErrorMessage, setProgress } = args;

  const [allRepos, setAllRepos] = useState<IRepository[]>([]);
  const [keys, setKeys] = useState<string[]>([]);

  const getRepoDetails = async () => {
    setErrorMessage("")
    try {
      const result = await userServices.getRemoteRepositories(
        gitServiceDetail?.authType
          ? gitServiceDetail?.authType.toLocaleLowerCase().split(" ")[0]
          : "",
        gitServiceDetail?.username ? gitServiceDetail?.username : "",
        gitServiceDetail?.password ? gitServiceDetail?.password : "",
        gitServiceDetail?.gitToken ? gitServiceDetail?.gitToken : "",
        gitServiceDetail?.serviceType
          ? gitServiceDetail?.serviceType.toLocaleLowerCase()
          : ""
      );

      if (result.status !== 200) {
        setErrorMessage('Wrong credential was added. Please try to input correct info again. Thanks')
        setProgress(0)
      } else {
        const temp: IRepository[] = [];
        const tempKey: string[] = [];
        result?.data?.map((_item: IRepository, index: number) => {
          temp.push(_item);
          tempKey.push(_item.name);
          return
        });
        setAllRepos(temp);
        setKeys(tempKey);
      }      
    } catch (error: any) {
      setErrorMessage(error)
    }
  };

  useEffect(() => {
    getRepoDetails();
  }, [gitServiceDetail]);

  return (
    <>
      <div
        style={{
          marginTop: "16px",
          fontSize: "16px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <div style={{ color: "grey" }}>1. Client Details</div>
        <div>/</div>
        <div>2. Repository Details</div>
        <div>/</div>
        <div style={{ color: "grey" }}>3. Initial Environment</div>
      </div>
      <div
        style={{
          marginTop: "16px",
          width: "36%",
        }}
      >
        <Select
          label="Select Repository"
          placeholder="Select"
          defaultValue={repository?.name}
          data={keys}
          value={repository?.name}
          disabled={keys.length > 0 ? false : true}
          onChange={(event: any) =>
            setRepository(
              allRepos[allRepos.findIndex((obj) => obj.name === event)]
            )
          }
        />
      </div>
    </>
  );
}
