import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, TextInput, Checkbox } from "@mantine/core";
import userServices from "../../services/userServices";
import { IEnvironmentDetail } from "../../models";
import MainLayout from "../../layouts/mainlayouts";

export default function AddEnvironment() {
  const { id } = useParams();
  const [clientName, setClientName] = useState("");
  const [initEnv, setInitEnv] = useState<IEnvironmentDetail>({
    designateProd: "Yes",
  });
  const [clientId, setClientId] = useState(-1);
  const [clientDetailDisable, setClientDetailDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate();

  const getClients = async () => {
    try {
      setErrorMessage("")
      const clientDetail = await userServices.getClientDetail(id)

      if (clientDetail.status === 200) {
        setClientId(clientDetail?.data?.id)
        setClientName(clientDetail?.data?.clientName)
      } else {
        setErrorMessage(clientDetail.data.result)
      }
    } catch (err: any) {
      setErrorMessage(err)
    }
  };

  const addEnvironmentClicked = async () => {
    try {
      setErrorMessage("")
      const result = await userServices.createFusionEnvironment(
        initEnv?.envName ? initEnv?.envName : "",
        initEnv?.url ? initEnv?.url : "",
        initEnv?.port ? initEnv?.port : "",
        "password",
        initEnv?.envUsername ? initEnv?.envUsername : "",
        initEnv?.envPassword ? initEnv?.envPassword : "",
        "",
        clientId,
        initEnv?.ssl ? initEnv?.ssl : true,
        false
      );

      if (result.status === 200) {
        setClientDetailDisable(false);
        setErrorMessage('')
        navigate("/dashboard");
      } else {
        setClientDetailDisable(true);
        setErrorMessage(result.data.result)
      }
    } catch (error: any) {
      setClientDetailDisable(true);
      setErrorMessage('Fusion environment addition failed. Please try again. Thanks')
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  const cancelClicked = () => {
    navigate("/dashboard");
  };

  const clientDisableHandle = () => {
    setClientDetailDisable(
      initEnv?.envName !== undefined &&
      initEnv?.envPassword !== undefined &&
      initEnv?.envName !== undefined &&
      initEnv?.url !== undefined
    );
  };

  useEffect(() => {
    clientDisableHandle();
  }, [clientName, initEnv]);

  return (
    <MainLayout 
      pageComponent={
        <>
          <div
            style={{
              marginTop: "64px",
              fontSize: "32px",
              fontWeight: "600",
            }}
          >
            Add Environment for client {clientName}
          </div>
          <div
          style={{
            marginTop: "16px",
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <span>1. Environment Detail</span>
        </div>
        <div style={{ width: "36%" }}>
          <div
            style={{
              marginTop: "24px",
            }}
          >
            <TextInput
              label="Environment Name"
              value={initEnv?.envName}
              onChange={(event: any) =>
                setInitEnv({ ...initEnv, envName: event?.currentTarget?.value })
              }
            />
          </div>
          <div
            style={{
              marginTop: "16px",
            }}
          >
            <TextInput
              label="Designate Production?"
              value="No (default)"
              readOnly
            />
          </div>
          <div
            style={{
              marginTop: "16px",
            }}
          >
            <TextInput
              label="URL"
              value={initEnv?.url}
              onChange={(event: any) =>
                setInitEnv({ ...initEnv, url: event?.currentTarget?.value })
              }
            />
          </div>
          <div
            style={{
              marginTop: "16px",
            }}
          >
            <TextInput
              label="Port"
              value={initEnv?.port}
              onChange={(event: any) =>
                setInitEnv({ ...initEnv, port: event?.currentTarget?.value })
              }
            />
          </div>
          <div
            style={{
              marginTop: "16px",
            }}
          >
            <TextInput
              label="Environment Username"
              value={initEnv?.envUsername}
              onChange={(event: any) =>
                setInitEnv({
                  ...initEnv,
                  envUsername: event?.currentTarget?.value,
                })
              }
            />
          </div>
          <div
            style={{
              marginTop: "16px",
            }}
          >
            <TextInput
              label="Environment Password"
              value={initEnv?.envPassword}
              onChange={(event: any) =>
                setInitEnv({
                  ...initEnv,
                  envPassword: event?.currentTarget?.value,
                })
              }
            />
          </div>
          <div
            style={{
              marginTop: "16px",
            }}
          >
            <Checkbox
              label="SSL"
              defaultChecked
              onChange={(event: any) =>
                setInitEnv({
                  ...initEnv,
                  ssl: event?.currentTarget?.checked,
                })
              }
            />
          </div>
        </div>
          <p style={{color: 'red'}}>{errorMessage}</p>
          <div
            style={{
              marginTop: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "36%",
            }}
          >
            
            <div>
              <Button
                radius="md"
                onClick={() => addEnvironmentClicked()}
                disabled={!clientDetailDisable}
              >
                Finish
              </Button>
            </div>
            <div>
              <Button radius="md" onClick={() => cancelClicked()}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      }
    />
  );
}
