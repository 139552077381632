import { useNavigate } from "react-router-dom";
import { Select, Button, Card, Group, Title } from "@mantine/core";
import gitIcon from "../assets/github.svg";
import bbIcon from "../assets/bitbucket.svg";
import { useEffect, useState, useContext } from "react";
import PromoteChange from "./promoteChange";
import { IClientCardDetail, IAppDetail } from "../models";
import userServices from "../services/userServices";
import { AuthContext } from "../contexts/authContext";

export default function ClientCard(args: IClientCardDetail) {
  const {clientName, clientId, repoDetail, fusionDetail} = args;
  const navigate = useNavigate();
  const authContext = useContext(AuthContext)

  const [sourceData, setSourceData] = useState<string[]>([])
  const [appData, setAppData] = useState<string[]>([])
  const [sourceSelected, setSourceSelected] = useState("")
  const [appSelected, setAppSelected] = useState("")
  const [srcenvIdSelected, setSrcEnvIdSelected] = useState(-1)
  const [trgenvIdSelected, setTrgEnvIdSelected] = useState(-1)
  const [targetSelected, setTargetSelected] = useState()
  const [promoteClicked, setPromoteClicked] = useState(false)
  const [promoteEnabled, setPromoteEnabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [appDetail, setAppDetail] = useState<IAppDetail[]>([])

  const getAppDetails = async () => {
    let tmpApp: IAppDetail[] = []
    for (const envDetail of fusionDetail) {
      try {
        const fusionApps = await userServices.getFusionEnvironmentApps(clientId, envDetail?.id)
        if (fusionApps.status === 200) {
          tmpApp.push({
            id: envDetail?.id,
            envName: envDetail?.name,
            target: envDetail?.isProduction,
            detail: fusionApps.data.results
          })
        } else if (fusionApps.status === 403) {
          authContext.signOut();
          navigate("/");
        }
      } catch (error: any) {
        setErrorMessage(error)
      }
    }
    setAppDetail(tmpApp)
  }

  useEffect(() => {
    let tmpSource: string[] = []
    fusionDetail.map((item: any) => {
      tmpSource.push(item.name)
      return
    })
    setSourceData(tmpSource)
    getAppDetails()    
  }, [fusionDetail])

  useEffect(() => {
    const filtered = appDetail?.filter((obj: IAppDetail) => obj.envName === sourceSelected)

    if (filtered.length >= 1) {
      let tmpApp: string[] = []
      filtered[0]?.detail?.map((item: any) => {
        tmpApp.push(item.id)
        return 
      })
      setAppData(tmpApp)
      setSrcEnvIdSelected(filtered[0].id)
    }
  }, [sourceSelected, appDetail])

  useEffect(() => {
    setPromoteEnabled(sourceSelected !== '' && appSelected !== '' && targetSelected !== '' && sourceSelected !== targetSelected)
    if (sourceSelected === targetSelected) {
      setErrorMessage("Source and target cannot be the same. Please try the different one.")
    } else {
      setErrorMessage("")
      const filtered = appDetail?.filter((obj: IAppDetail) => obj.envName === targetSelected)

      if (filtered.length >= 1) {
        let tmpApp: string[] = []
        filtered[0]?.detail?.map((item: any) => {
          tmpApp.push(item.id)
          return tmpApp
        })
        setTrgEnvIdSelected(filtered[0].id)
      }
    }
  }, [sourceSelected, appSelected, targetSelected])

  const editClientClicked = () => {
    navigate(`/client/update/${clientId}`)
  }

  const addEnvironmentClicked = () => {
    navigate(`/client/addenvironment/${clientId}`)
  }

  // const promoteClicked = () => {
  //   navigate(`/client/promote/${clientId}`)
  // }

  return promoteClicked ? 
    <PromoteChange 
      clientId={clientId} 
      clientName={clientName} 
      srcEnvId={srcenvIdSelected}
      trgEnvId={trgenvIdSelected}
      appId={appSelected} 
      repoDetail={repoDetail}
      setPromoteClicked={setPromoteClicked}
    /> 
    : 
    (
    <Card withBorder radius="md" p="md" style={{ width: "100%" }}>
      <Card.Section
        style={{
          borderBottom: "1px solid gray",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              height: "40px",
            }}
          >
            <img src={repoDetail?.serverType === 'github' ? gitIcon : bbIcon} style={{ width: "28px", height: "28px" }} alt=""/>
            <div style={{ fontSize: "28px" }}>{clientName}</div>
          </div>
          <div
            style={{
              textDecoration: "underline",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onClick={editClientClicked}
          >
            Edit Client
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginTop: '10px'
          }}
        >
          <div>
            <Title order={6}>Environments</Title>
            <div
              style={{
                textAlign: "center",
                fontSize: "10px",
                color: "white",
                backgroundColor: "black",
                padding: "1px 4px",
                borderRadius: "15px",
                marginTop: '4px',
                marginBottom: '4px'
              }}
            >
              PRODUCTION
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "10px",
                gap: '4px',
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  color: "black",
                  padding: "1px 4px",
                  borderRadius: "8px",
                  border: '1px solid gray',
                }}
              >
                MODAL
              </div>
              <div
                style={{
                  color: "black",
                  padding: "1px 4px",
                  borderRadius: "8px",
                  border: '1px solid gray'
                }}
              >
                QA
              </div>
              <div
                style={{
                  color: "black",
                  padding: "1px 4px",
                  borderRadius: "8px",
                  border: '1px solid gray'
                }}
              >
                DEV
              </div>
            </div>
          </div>
          <div
            style={{
              textDecoration: "underline",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onClick={addEnvironmentClicked}
          >
            Add Environment
          </div>
        </div>
        <Group>
          <div
            style={{
              marginTop: "16px",
              width: "100%",
            }}
          >
            <Select
              label="Source"
              placeholder="Select Source"
              data={sourceData}
              value={sourceSelected}
              onChange={(event: any) =>
                setSourceSelected(event)
              }
            />
          </div>
          <div
            style={{
              marginTop: "16px",
              width: "100%",
            }}
          >
            <Select
              label="App"
              placeholder="Select App"
              data={appData}
              value={appSelected}
              onChange={(event: any) =>
                setAppSelected(event)
              }
            />
          </div>
          <div
            style={{
              marginTop: "16px",
              width: "100%",
            }}
          >
            <Select
              label="Target"
              placeholder="Select Target"
              data={sourceData}
              value={targetSelected}
              defaultValue={targetSelected}
              onChange={(event: any) =>
                setTargetSelected(event)
              }
            />
          </div>
        </Group>
      </Card.Section>
      <Card.Section style={{ padding: "20px" }}>
        <p style={{color: 'red'}}>{errorMessage}</p>
        <div>
          <Button radius="md" disabled={!promoteEnabled} onClick={() => setPromoteClicked(!promoteClicked)}>Promote Change</Button>
        </div>
      </Card.Section>
    </Card>
  );
}
