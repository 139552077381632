import React, { useState } from 'react';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';

interface CollapseItemProps {
  title: string;
  isCheckbox?: boolean;
  level?: number;
  isChecked?: boolean;
  onItemChange?: (checked: boolean) => void;
  children?: React.ReactNode;
}

const CollapseItem: React.FC<CollapseItemProps> = ({ title, isCheckbox, level = 0, isChecked, onItemChange, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onItemChange) {
      onItemChange(e.target.checked);
    }
  };

  return (
    <div style={{padding: '1px'}}>
      <div
        onClick={handleToggleCollapse}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: level < 2 ? "1px solid black" : "", 
          borderRadius: "5px",
          paddingLeft: '5px',
          height: level < 2 ? "44px" : "32px"
        }}
      >
        <div style={{display: "flex", justifyContent: "flex-start", gap: "5px"}}>
          {isCheckbox && (
            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
          )}
          <p style={{display: "flex", justifyContent: "flex-start", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{title}</p>
        </div>
        {level >= 2 ? <></> : (isCollapsed ? <IconChevronDown /> : <IconChevronRight />)}
      </div>
      {isCollapsed && <div style={{ marginLeft: 20 }}>{children}</div>}
    </div>
  );
};

export default CollapseItem;