/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from "react-router-dom";
import { Button, Card, Group, Title, TextInput, Textarea } from "@mantine/core";
import gitIcon from "../assets/github.svg";
import bbIcon from "../assets/bitbucket.svg";
import { useEffect, useState } from "react";
import userServices from "../services/userServices";
import { IPromote } from "../models";
import CollapseItem from "./collapseItems/CollapseItems";
import { Loader } from '@mantine/core';
import { toastSuccess } from "../utils/toasts";

interface Target {
  [key: string]: { id: string }[];
}

interface SolrObject {
  name: string;
  version: number;
  isDir: boolean;
}

interface Collection {
  id: string;
  include: boolean;
  solrObjects: SolrObject[];
}

interface TargetCollection {
  collections: Collection[];
}

export default function PromoteChange(args: IPromote) {
  const {clientId, clientName, srcEnvId, trgEnvId, appId, repoDetail, setPromoteClicked} = args;
   const navigate = useNavigate();
  const [data, setData] = useState<any>({})
  const [dataKeys, setDataKeys] = useState<string[]>([])
  const [indexData, setIndexData] = useState({})
  const [queriesData, setQueriesData] = useState({})
  const [collectionsData, setCollectionsData] = useState({})
  const [relevancyData, setRelevancyData] = useState({})
  const [systemData, setSystemData] = useState({})
  const [errorMessage, setErrorMessage] = useState("")
  const [status, setStatus] = useState(0)
  const [prTitle, setPrTitle] = useState("")
  const [prDescription, setPrDescription] = useState("")
  const [promoteDisable, setPromotionDisable] = useState(false)

  const [promoteEnabled, setPromoteEnabled] = useState(false)

  const getAppObjects = async () => {
    try {
      setErrorMessage("")
      const result = await userServices.getFusionAppObjects(clientId, srcEnvId, appId)
      if (result.status === 200) {
        setData(result.data)
        setDataKeys(Object.keys(result.data))
      } else {
        setErrorMessage(result.data)
      }
    } catch (error: any) {
      setErrorMessage(error)
    }
  }

  useEffect(() => {
    getAppObjects()
  }, [])

  const promoteClicked = async () => {
    if (status === 0) {
      setStatus(1)
    } else if (status === 1) {
      try {
        setErrorMessage("")
        const result = await userServices.promoteAChange(
          indexData, 
          queriesData, 
          collectionsData, 
          relevancyData, 
          systemData, 
          srcEnvId, 
          trgEnvId, 
          clientId,
          appId,
          prTitle,
          prDescription
        )
        if (result.status === 200) {
          setPromoteClicked(false)
          toastSuccess('Promotion successfully submitted. Please review Git to approve change.')
        }
      } catch (error: any) {
        setErrorMessage(error)
      }
    }
  }

  const cancelClicked = () => {
    setPromoteClicked(false)
  }

  useEffect(() => {
    if (prTitle.length > 20 && prDescription.length > 50) {
      setPromotionDisable(false)
    } else {
      setPromotionDisable(true)
    }
  }, [prTitle, prDescription])

  const promoteTitleDescription = (
    <>
      <div
        style={{
          width: "100%",
          marginTop: '20px'
        }}
      >
        <Title order={6}>Title (min 20 characters)</Title>
        <TextInput
          label="This is used as the PR title"
          value={prTitle}
          onChange={(event: any) => 
            setPrTitle(event?.currentTarget?.value)
          }
        />
      </div>
      <div
        style={{
          width: "100%",
          marginTop: '20px',
        }}
      >
        <Title order={6}>Description (min 50 characters)</Title>
        <Textarea
          label="This is used as the PR description"
          autosize
          minRows={8}
          value={prDescription}
          onChange={(event: any) => 
            setPrDescription(event?.currentTarget?.value)
          }
          // value={gitServiceDetail?.gitToken}
          // onChange={(event: any) =>
          //   setGitServiceDetail({
          //     ...gitServiceDetail,
          //     gitToken: event?.currentTarget?.value,
          //   })
          // }
        />
      </div>
    </>
  )

  const [checkedItems, setCheckedItems] = useState<{ [itemId: string]: boolean }>({});

  const handleItemChange = (itemId: string, checked: boolean) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: checked,
    }));
  };

  const handleCheckedItems = () => {
    const targetIndex: Target = {};
    const targetQueries: Target = {};
    const targetRelevancy: Target = {};
    const targetSystem: Target = {};
    // const targetCollections: any = {};
    const targetCollections: TargetCollection = { collections: [] };

    for (const [key, value] of Object.entries(checkedItems)) {
      const keyParts = key.split('/');
      if (!value) {
        continue;
      }

      if (keyParts[0] === 'index') {
        const path = key.split("index/")[1];
        const [category, id] = path.split("/");
        if (!targetIndex[category]) {
          targetIndex[category] = [];
        }
        targetIndex[category].push({ id });
      } 
      
      else if (keyParts[0] === 'queries') {
        const path = key.split("queries/")[1];
        const [category, id] = path.split("/");
        if (!targetQueries[category]) {
          targetQueries[category] = [];
        }
        targetQueries[category].push({ id });
      } 
      
      else if (keyParts[0] === 'relevancy') {
        const path = key.split("relevancy/")[1];
        const [category, id] = path.split("/");
        if (!targetRelevancy[category]) {
          targetRelevancy[category] = [];
        }
        targetRelevancy[category].push({ id });
      } 
      
      else if (keyParts[0] === 'system') {
        const path = key.split("system/")[1];
        const [category, id] = path.split("/");
        if (!targetSystem[category]) {
          targetSystem[category] = [];
        }
        targetSystem[category].push({ id });
      }

      else if (keyParts[0] === 'collections') {
        // Skip if the value is false
        if (!value) {
          continue;
        }

        const collectionId = keyParts[2];

        // Check if the collection already exists in the target
        const existingCollection = targetCollections.collections.find(c => c.id === collectionId);

        if (keyParts.length === 4) {
          if (existingCollection) {
            // Add a new solrObject to the existing collection
            existingCollection.solrObjects.push({
              name: keyParts[keyParts.length - 1],
              version: 1,
              isDir: false
            });
          } else {
            // Add a new collection to the target
            targetCollections.collections.push({
              id: collectionId,
              include: true,
              solrObjects: [{
                name: keyParts[keyParts.length - 1],
                version: 1,
                isDir: false
              }]
            });
          }
        } else {
          if (!existingCollection) {
            targetCollections.collections.push({
              id: collectionId,
              include: true,
              solrObjects: []
            });
          }
        }
      }
    }

    setIndexData(targetIndex)
    setQueriesData(targetQueries)
    setRelevancyData(targetRelevancy)
    setSystemData(targetSystem)
    setCollectionsData(targetCollections)
  }

  useEffect(() => {
    handleCheckedItems()
  }, [checkedItems])

  return (
    <Card withBorder radius="md" p="md" style={{ width: "100%"}}>
      <Card.Section
        style={{
          borderBottom: "1px solid gray",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              height: "40px",
            }}
          >
            <img src={repoDetail?.serverType === 'github' ? gitIcon : bbIcon} style={{ width: "28px", height: "28px" }} alt="" />
            <div style={{ fontSize: "28px" }}>{clientName}</div>
          </div>
          <div
            style={{
              fontSize: "12px",
              cursor: "pointer",
            }}
          >
            Promote a Change
          </div>
        </div>
        {
          status === 0 ?
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginTop: '10px'
              }}
            >
              <div>
                <Title order={6}>Select Configurations to Promote</Title>            
              </div>
            </div>
            <Group 
              style={{
                maxHeight: "320px",
                minHeight: "280px",
                overflowY: "auto"
              }}
            >
              {
                Object.keys(data).length > 0 ? 
                  <div style={{ width: '100%' }}>
                    {(Object.entries(data) as [string, unknown][]).map(([section, data]) => (
                      <CollapseItem title={section} key={section}>
                        {section === 'collections' ? (
                          Object.entries(data as { [key: string]: unknown[] }).map(([subSection, items]) => (
                            <CollapseItem title={subSection} level={1} key={subSection}>{
                              Array.isArray((data as { collections: unknown[] }).collections) &&
                              (data as { collections: unknown[] }).collections.map((collection) => (
                                <CollapseItem 
                                  title={(collection as { id: string }).id}
                                  level={2}
                                  isCheckbox
                                  isChecked={checkedItems[section + '/' + subSection + '/' + (collection as { id: string }).id]}
                                  onItemChange={(checked) => handleItemChange(section + '/' + subSection + '/' + (collection as { id: string }).id, checked)}
                                  key={(collection as { id: string }).id}
                                >
                                  {Array.isArray((collection as { solrObjects: unknown[] }).solrObjects) &&
                                    (collection as { solrObjects: unknown[] }).solrObjects.map((solrObject) => (
                                      <CollapseItem
                                        title={(solrObject as { name: string }).name}
                                        level={3}
                                        key={(solrObject as { name: string }).name}
                                        isCheckbox
                                        isChecked={
                                          checkedItems[section + '/' + subSection + '/' + (collection as { id: string }).id + '/' + (solrObject as { name: string }).name]
                                        }
                                        onItemChange={(checked) =>
                                          handleItemChange(section + '/' + subSection + '/' + (collection as { id: string }).id + '/' + (solrObject as { name: string }).name, checked)
                                        }
                                      ></CollapseItem>
                                    ))}
                                </CollapseItem>
                              ))}
                            </CollapseItem>
                          ))
                        ) : (
                          // Render other sections
                          Object.entries(data as { [key: string]: unknown[] }).map(([subSection, items]) => (
                            <CollapseItem title={subSection} level={1} key={subSection}>
                              {Array.isArray(items) &&
                                items.map((item) => (
                                  <CollapseItem
                                    title={(item as { id: string }).id}
                                    level={2}
                                    isCheckbox
                                    isChecked={checkedItems[section + '/' + subSection + '/' + (item as { id: string }).id]}
                                    onItemChange={(checked) => handleItemChange(section + '/' + subSection + '/' + (item as { id: string }).id, checked)}
                                    key={(item as { id: string }).id}
                                  ></CollapseItem>
                                ))}
                            </CollapseItem>
                          ))
                        )}
                      </CollapseItem>
                    ))}
                  </div>
                    : 
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "320px", width: '100%'}}><Loader size={50} /></div>
              }          
            </Group>
          </> 
          : 
          promoteTitleDescription }
        
      </Card.Section>
      <Card.Section style={{ padding: "20px" }}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Button radius="md" onClick={promoteClicked} disabled={status === 0 ? false : promoteDisable}>{status === 0 ? "Next" : "Promote Change"}</Button>
          <Button radius="md" onClick={cancelClicked}>Cancel</Button>
        </div>
      </Card.Section>
    </Card>
  );
}
