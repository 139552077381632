import axios from 'axios';
import authHeader from './authHeader';

const API_URL = process.env.REACT_APP_BASEURI;

class UserService {
  async getClientsList() {
    return axios.get(API_URL + 'clients', { headers: authHeader() })
    .then(response => {
      return response;
    }).catch(error => {
      return error.response
    });;
  }

  async createClient(clientName) {
    return axios.post(API_URL + 'clients', 
    { clientName }, 
    { headers: authHeader() })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async createRepository(name, url, serverType, authType, password, username, authToken, clientId) {
    return axios.post(API_URL + 'repositories', { 
      name, url, serverType, authType, password, username, authToken, clientId 
    }, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async createFusionEnvironment(name, host, port, authType, username, password, apikey, clientId, ssl, isProduction) {
    return axios.post(API_URL + 'fusionEnvironments', { 
      name, host, port, authType, username, password, apikey, clientId, ssl, isProduction
    }, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getRemoteRepositories(authMethod, username, password, token, server) {
    return axios.post(API_URL + 'remoteRepositories', { 
      authMethod, username, password, token, server
    }, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getClientDetail(clientId) {
    return axios.get(API_URL + `clients/${clientId}`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getClientRepositories(clientId) {
    return axios.get(API_URL + `repositories/${clientId}`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async updateClientRepositories(name, url, serverType, authType, password, username, authToken, clientId, repositoryId) {
    return axios.put(API_URL + 'repositories', { 
      name, url, serverType, authType, password, username, authToken, clientId, repositoryId
    }, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getFusionEnvironment(clientId) {
    return axios.get(API_URL + `fusionEnvironments/${clientId}`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getFusionEnvironmentApps(clientId, envId) {
    return axios.get(API_URL + `fusionEnvironments/${clientId}/${envId}`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getFusionAppObjects(clientId, envId, appId) {
    return axios.get(API_URL + `fusionEnvironments/${clientId}/${envId}/${appId}/objects`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async updateFusionEnvironment(clientId, envId, name, host, port, authType, username, password, apikey, ssl) {
    return axios.put(API_URL + `fusionEnvironments/${clientId}/${envId}`, { 
      name, host, port, authType, username, password, apikey, ssl
    }, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async promoteAChange(
    index, 
    queries, 
    collections, 
    relevancy, 
    system, 
    sourceEnvironmentId, 
    targetEnvironmentId, 
    clientId, 
    appId, 
    promotionTitle, 
    promotionDescription
  ) {
    return axios.post(API_URL + 'promoteChange', { 
      index, 
      queries, 
      collections, 
      relevancy, 
      system, 
      sourceEnvironmentId, 
      targetEnvironmentId, 
      clientId, 
      appId, 
      promotionTitle, 
      promotionDescription
    }, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async createUser(username, password, firstName, lastName, email, active) {
    return axios.post(API_URL + 'users', { 
      username, password, firstName, lastName, email, active
    }, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getUsers() {
    return axios.get(API_URL + `users`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getUserById(id) {
    return axios.get(API_URL + `users/${id}`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async updateUser(id, username, password, firstName, lastName, active) {
    return axios.put(API_URL + `users/${id}`, { 
      username, password, firstName, lastName, active
    }, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getActivity(page, limit = 20) {
    return axios.get(API_URL + `activities?page=${page}&limit=${limit}`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async getPendingPromotions(page, limit = 20) {
    return axios.get(API_URL + `promotions?page=${page}&limit=${limit}&status=PR-Raised&status=PendingDeployment`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }

  async startDeployment(id) {
    return axios.get(API_URL + `runPromotion/${id}`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });    
  }

  async getAllPromotions(page, limit = 20) {
    return axios.get(API_URL + `promotions?page=${page}&limit=${limit}`, { 
      headers: authHeader() 
    })
      .then(response => {
        return response;
      }).catch(error => {
        return error.response
      });
  }
}
/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new UserService();