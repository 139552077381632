import React, { createContext, useState } from "react";

type ThemeContextType = {
  status: boolean;
  setStatus: (status: boolean) => void;
};

const ThemeContext = createContext<ThemeContextType>({
  status: false,
  setStatus: () => {},
});

type ThemeContextProviderProps = {
  children: React.ReactNode;
};

const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({
  children,
}: ThemeContextProviderProps) => {
  const [status, setStatus] = useState<boolean>(false);

  const handleSetStatue = (newStatus: boolean) => {
    setStatus(newStatus);
  };

  return (
    <ThemeContext.Provider value={{ status, setStatus: handleSetStatue }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
