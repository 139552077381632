import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const MyA = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
`;

export default function MenuBar({
  selected,
  setSelected,
}: {
  selected: string;
  setSelected: (_: string) => void;
}) {
  const navigate = useNavigate()

  const overviewClicked = () => {
    setSelected("Overview")
    navigate("/dashboard")
  }

  const promotionsClicked = () => {
    setSelected("Promotions")
    navigate("/dashboard/promotions")
  }

  const activityClicked = () => {
    setSelected("Activity")
    navigate("/dashboard/activity")
  }

  const settingsClicked = () => {
    setSelected("Settings")
    navigate("/dashboard/settings")
  }
  
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        color: "black",
        fontSize: "16px",
        marginTop: "16px",
        gap: "16px",
        cursor: "pointer",
        fontWeight: "600"
      }}
    >
      <MyA
        onClick={() => overviewClicked()}
        style={{
          textDecoration: selected === "Overview" ? "underline" : "",
        }}
      >
        Overview
      </MyA>
      <MyA
        onClick={() => promotionsClicked()}
        style={{
          textDecoration: selected === "Promotions" ? "underline" : "",
        }}
      >
        Promotions
      </MyA>
      <MyA
        onClick={() => activityClicked()}
        style={{
          textDecoration: selected === "Activity" ? "underline" : "",
        }}
      >
        Activity
      </MyA>
      <MyA
        onClick={() => settingsClicked()}
        style={{
          textDecoration: selected === "Settings" ? "underline" : "",
        }}
      >
        Settings
      </MyA>
    </div>
  );
}
