/* eslint-disable jsx-a11y/anchor-is-valid */
import {TextInput, Checkbox } from "@mantine/core";
import { IInitialEnvironment } from "../../models";

export default function InitialEnvironment(args: IInitialEnvironment) {
  const { initEnv, setInitEnv } = args;
  return (
    <>
      <div
        style={{
          marginTop: "16px",
          fontSize: "16px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <a style={{ color: "grey" }}>1. Client Details</a>
        <div>/</div>
        <a style={{ color: "grey" }}>2. Repository Details</a>
        <div>/</div>
        <a>3. Initial Environment</a>
      </div>
      <div style={{ width: "36%" }}>
        <div
          style={{
            marginTop: "24px",
          }}
        >
          <TextInput
            label="Environment Name"
            value={initEnv?.envName}
            onChange={(event: any) =>
              setInitEnv({ ...initEnv, envName: event?.currentTarget?.value })
            }
          />
        </div>
        {/* <div
          style={{
            marginTop: "16px",
          }}
        >
          <Select
            label="Designate Production?"
            data={["Yes"]}
            defaultValue="Yes"
            // value={initEnv?.designateProd}
            // onChange={(event: any) =>
            //   setInitEnv({ ...initEnv, designateProd: event })
            // }
          />
        </div> */}
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <TextInput
            label="Designate Production?"
            value="Yes"
            readOnly
          />
        </div>
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <TextInput
            label="URL"
            value={initEnv?.url}
            onChange={(event: any) =>
              setInitEnv({ ...initEnv, url: event?.currentTarget?.value })
            }
          />
        </div>
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <TextInput
            label="Port"
            value={initEnv?.port}
            onChange={(event: any) =>
              setInitEnv({ ...initEnv, port: event?.currentTarget?.value })
            }
          />
        </div>
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <TextInput
            label="Environment Username"
            value={initEnv?.envUsername}
            onChange={(event: any) =>
              setInitEnv({
                ...initEnv,
                envUsername: event?.currentTarget?.value,
              })
            }
          />
        </div>
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <TextInput
            label="Environment Password"
            value={initEnv?.envPassword}
            onChange={(event: any) =>
              setInitEnv({
                ...initEnv,
                envPassword: event?.currentTarget?.value,
              })
            }
          />
        </div>
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <Checkbox
            label="SSL"
            defaultChecked
            onChange={(event: any) =>
              setInitEnv({
                ...initEnv,
                ssl: event?.currentTarget?.checked,
              })
            }
          />
        </div>
      </div>
    </>
  );
}
