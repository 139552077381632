import { TextInput, Button, PasswordInput, Checkbox } from "@mantine/core";
import { IUsersEditDetail } from "../../models";
import { useState, useEffect } from "react";
import userServices from "../../services/userServices";
import { toastSuccess, toastError } from "../../utils/toasts";

export default function EditUser(args: IUsersEditDetail) {
  const { userDetail, setUserDetail, setUserEdit, userId } = args;
  const [enabled, setEnabled] = useState(false)

  const getUserDetails = async () => {
    try {
      const user = await userServices.getUserById(userId)
      if (user.status === 200) {
        setUserDetail({
          username: user.data.username,
          firstname: user.data.firstName,
          lastname: user.data.lastName,
          password: "",
          passwordConfirm: "",
          active: user.data.active
        })
      } else {
        toastError(user.data.result)
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const updateUserHandle = async () => {
    try {
      const user = await userServices.updateUser(
        userId,
        userDetail.username,
        userDetail.password,
        userDetail.firstname,
        userDetail.lastname,
        userDetail.active
      )
      if (user.status === 200) {
        setUserEdit(false)
        toastSuccess('User modified successfully.')
      } else {
        setUserEdit(true)
        toastError(user.data.result)
      }
    } catch (error: any) {
      setUserEdit(true)
      toastError('User modification failed. Please try again with correct info.')
    }
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  useEffect(() => {
    setEnabled(
      userDetail.username !== "" &&
      userDetail.firstname !== "" &&
      userDetail.lastname !== "" &&
      userDetail.password !== "" &&
      userDetail.password === userDetail.passwordConfirm
    )
  }, [userDetail])

  return (
    <div style={{ width: "36%" }}>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <TextInput
          label="Username"
          placeholder="Username"
          value={userDetail.username}
          onChange={(event: any) => setUserDetail({...userDetail, username: event.currentTarget.value})}
        />
      </div>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <TextInput
          label="First Name"
          placeholder="Firstname"
          value={userDetail.firstname}
          onChange={(event: any) => setUserDetail({...userDetail, firstname: event.currentTarget.value})}
        />
      </div>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <TextInput
          label="Last Name"
          placeholder="Lastname"
          value={userDetail.lastname}
          onChange={(event: any) => setUserDetail({...userDetail, lastname: event.currentTarget.value})}
        />
      </div>
      <div
        style={{
          marginTop: "24px",
        }}
      >
        <PasswordInput
          value={userDetail.password}
          onChange={(event: any) => setUserDetail({...userDetail, password: event.currentTarget.value})}
          label="Password"
          placeholder="Password"
          required
        />
      </div>
      <div
        style={{
          marginTop: "8px",
        }}
      >
        <PasswordInput
          value={userDetail.passwordConfirm}
          onChange={(event: any) => setUserDetail({...userDetail, passwordConfirm: event.currentTarget.value})}
          placeholder="Confirm Password"
          required
        />
      </div>
      <div
          style={{
            marginTop: "16px",
          }}
        >
          <Checkbox
            label="Active"
            checked={userDetail.active}
            onChange={(event: any) =>
              setUserDetail({
                ...userDetail,
                active: event?.currentTarget?.checked
              })
            }
          />
        </div>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px"
      }}>
        <Button
          onClick={() => updateUserHandle()}
          disabled={!enabled}
        >
          Save User
        </Button>
      </div>
    </div>
  );
}
