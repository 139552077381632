import { useState, useEffect, useContext } from "react";
import { Button, Grid } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import userServices from "../../services/userServices";
import MainLayout from "../../layouts/mainlayouts";
import ClientCard from "../../components/clientCard";
import { IClientCardDetail } from "../../models";
import MenuBar from "../../components/menuBar";
import { Loader } from '@mantine/core';
import { AuthContext } from "../../contexts/authContext";
import { toastError } from "../../utils/toasts";

export default function Home() {
  const [clientCardDetail, setClientCardDetail] = useState<IClientCardDetail[]>([])
  const navigate = useNavigate();
  const authContext = useContext(AuthContext)
  const createClientClicked = async () => {
    navigate("/client/create");
  };
  const [selected, setSelected] = useState("Overview");
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const getClients = async () => {
    try {
      setErrorMessage("")
      setLoading(true)
      const clients = await userServices.getClientsList();
      let tmp: IClientCardDetail[] = []
      if (clients.status === 200) {
        for (const item of clients?.data) {
          const [
            clientRepo,
            fusionEnv,
          ] = await Promise.all([
            userServices.getClientRepositories(item.id),
            userServices.getFusionEnvironment(item.id),
          ]);
          if (clientRepo.status === 200 && fusionEnv.status === 200) {
            setErrorMessage("")            
            tmp.push({
              clientId: item.id,
              clientName: item.clientName,
              repoDetail: clientRepo.data[0],
              fusionDetail: fusionEnv.data,
            })
          } else if (clientRepo.status === 403 || fusionEnv.status === 403) {
            authContext.signOut();
            navigate("/");
          }
        }
        setClientCardDetail(tmp)
        setLoading(false)
      } else if (clients.status === 403) {
        authContext.signOut();
        navigate("/");
      } else {
        setLoading(false)
        toastError(clients.data.error)
      }
    } catch (err: any) {
      setErrorMessage(err)
      setLoading(false)
      toastError("Something went wrong. Please refresh the page and try again")
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <MainLayout
      pageComponent={
        !loading ? 
        <>
          <MenuBar selected={selected} setSelected={setSelected} />
          {clientCardDetail?.length > 0 ? (
            <div style={{position: "relative"}}>
              <div
                style={{
                  marginTop: "24px",
                  position: "absolute",
                  top: '-119px',
                  right: '96px'
                }}
              >
                <Button radius="md" onClick={() => createClientClicked()}>Create New Client</Button>
              </div>
              <Grid style={{ paddingTop: "24px" }}>
                {clientCardDetail.map((_item: IClientCardDetail, index: number) => 
                  <Grid.Col sm={12} md={6} lg={4} key={index}>
                    <ClientCard 
                      clientId={_item.clientId} 
                      clientName={_item.clientName} 
                      repoDetail={_item.repoDetail} 
                      fusionDetail={_item.fusionDetail}
                    />
                  </Grid.Col>
                )}
              </Grid>
            </div>
          ) : (
            <>
              <div
                style={{
                  marginTop: "32px",
                  fontSize: "32px",
                  fontWeight: "600",
                }}
              >
                Let's get started
              </div>
              <div
                style={{
                  marginTop: "16px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                To make a deployment, create a new Client
              </div>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <Button radius="md" onClick={() => createClientClicked()}>Create New Client</Button>
              </div>
            </>
          )}
        </>
        :
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: '100%'}}><Loader size={50} /></div>
      }
    />
  );
}
