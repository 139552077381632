import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../views/home";
import CreateClient from "../views/clients/createClient";
import UpdateClient from "../views/clients/updateClient";
import AddEnvironment from "../views/clients/addEnvironment";
import Settings from "../views/settings";
import Activity from "../views/activity";
import Promotions from "../views/promotions";
import LogIn from "../views/login";

export const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LogIn />} />
    </Routes>
  </Router>
);

export const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/dashboard" element={<Home />} />
      <Route path="/client/create" element={<CreateClient />} />
      <Route path="/client/update/:id" element={<UpdateClient />} />
      <Route path="/client/addenvironment/:id" element={<AddEnvironment />} />
      <Route path="/dashboard/settings" element={<Settings />} />
      <Route path="/dashboard/activity" element={<Activity />} />
      <Route path="/dashboard/promotions" element={<Promotions />} />
    </Routes>
  </Router>
);
