import { Paper, Title, Group, Button, Container } from "@mantine/core";
import { AuthContext } from "../../contexts/authContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UsernameStrength, Password } from "../../components/authComponents";
import { useValidUsername, useValidPassword } from "../../hooks/useAuthHooks";
import { toastError } from "../../utils/toasts";

export default function LogIn() {
  const authContext = useContext(AuthContext);
  const { username, setUsername, usernameIsValid } = useValidUsername("");
  const { password, setPassword, passwordIsValid } = useValidPassword("");
  const [error, setError] = useState("");

  const isValid =
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0;

  const navigate = useNavigate();
  const signInClicked = async () => {
    setError("")
    authContext
      .signInWithUsername(username, password)
      .then((res: any) => {
        if (res.status === 200) {
          navigate("dashboard");
        } else {
          toastError(res.data.error)
        }
      })
      .catch((err: any) => {
        if (err instanceof TypeError) {
          navigate("/");
        }
        if (err.code === "UserNotConfirmedException") {
          navigate("/");
        } else {
          setError(err.message);
        }
      });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "#E4E4E4",
        paddingLeft: "40px",
        paddingRight: "40px",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "1200px",
          display: "block",
          paddingTop: "32px",
          paddingBottom: "32px",
          height: "fit-content",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            backgroundColor: "#228be6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "20px",
          }}
        >
          M
        </div>
        <Container size={420} my={40}>
          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 900,
            })}
          >
            Log In
          </Title>
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <Group>
              <UsernameStrength username={username} setUsername={setUsername} />
            </Group>
            <Group style={{ marginTop: "8px" }}>
              <Password
                label="Enter your password"
                password={password}
                setPassword={setPassword}
              />
            </Group>
            <Button
              fullWidth
              mt="xl"
              disabled={isValid}
              onClick={signInClicked}
            >
              {/* <Button fullWidth mt="xl"> */}
              Sign in
            </Button>
          </Paper>
        </Container>
      </div>
    </div>
  );
}
