/* eslint-disable jsx-a11y/anchor-is-valid */
import { TextInput, Select } from "@mantine/core";
// import gitIcon from "../../assets/github.svg";
// import bbIcon from "../../assets/bitbucket.svg";
import { IClientDetail } from "../../models";

export default function ClientDetail(args: IClientDetail) {
  const { clientName, setClientName, gitServiceDetail, setGitServiceDetail } =
    args;

  return (
    <>
      <div
        style={{
          marginTop: "16px",
          fontSize: "16px",
          fontWeight: "500",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <a>1. Client Details</a>
        <div>/</div>
        <a style={{ color: "grey" }}>2. Repository Details</a>
        <div>/</div>
        <a style={{ color: "grey" }}>3. Initial Environment</a>
      </div>
      <div style={{ width: "36%" }}>
        <div
          style={{
            marginTop: "24px",
          }}
        >
          <TextInput
            label="Client name"
            value={clientName}
            onChange={(event: any) => setClientName(event.currentTarget.value)}
          />
        </div>
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <Select
            label="Git Service"
            placeholder="Select Git Service"
            data={["Github", "Bitbucket"]}
            value={gitServiceDetail?.serviceType}
            onChange={(event: any) =>
              setGitServiceDetail({
                ...gitServiceDetail,
                serviceType: event,
              })
            }
          />
        </div>
        {gitServiceDetail?.serviceType === "Github" ? (
          <div>
            <div
              style={{
                marginTop: "16px",
              }}
            >
              <Select
                label="Authentication Method"
                placeholder="Select"
                data={["Password Auth"]}
                value={gitServiceDetail?.authType}
                onChange={(event: any) =>
                  setGitServiceDetail({
                    ...gitServiceDetail,
                    authType: event,
                  })
                }
              />
            </div>
            {gitServiceDetail?.authType === "Token Auth" ? (
              <div>
                <div
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextInput
                    label="Github Username"
                    value={gitServiceDetail?.username}
                    onChange={(event: any) =>
                      setGitServiceDetail({
                        ...gitServiceDetail,
                        username: event?.currentTarget?.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextInput
                    label="Github Token"
                    value={gitServiceDetail?.gitToken}
                    onChange={(event: any) =>
                      setGitServiceDetail({
                        ...gitServiceDetail,
                        gitToken: event?.currentTarget?.value,
                      })
                    }
                  />
                </div>
              </div>
            ) : gitServiceDetail?.authType === "Password Auth" ? (
              <div>
                <div
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextInput
                    label="Github Username"
                    value={gitServiceDetail?.username}
                    onChange={(event: any) =>
                      setGitServiceDetail({
                        ...gitServiceDetail,
                        username: event?.currentTarget?.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextInput
                    label="Github Password"
                    value={gitServiceDetail?.password}
                    onChange={(event: any) =>
                      setGitServiceDetail({
                        ...gitServiceDetail,
                        password: event?.currentTarget?.value,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : gitServiceDetail?.serviceType === "Bitbucket" ? (
          <div>
            <div
              style={{
                marginTop: "16px",
              }}
            >
              <Select
                label="Authentication Method"
                placeholder="Select"
                data={["Password Auth"]}
                value={gitServiceDetail?.authType}
                onChange={(event: any) =>
                  setGitServiceDetail({
                    ...gitServiceDetail,
                    authType: event,
                  })
                }
              />
            </div>
            {gitServiceDetail?.authType === "Token Auth" ? (
              <div>
                <div
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextInput
                    label="Bitbucket Username"
                    value={gitServiceDetail?.username}
                    onChange={(event: any) =>
                      setGitServiceDetail({
                        ...gitServiceDetail,
                        username: event?.currentTarget?.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextInput
                    label="Bitbucket Token"
                    value={gitServiceDetail?.gitToken}
                    onChange={(event: any) =>
                      setGitServiceDetail({
                        ...gitServiceDetail,
                        gitToken: event?.currentTarget?.value,
                      })
                    }
                  />
                </div>
              </div>
            ) : gitServiceDetail?.authType === "Password Auth" ? (
              <div>
                <div
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextInput
                    label="Bitbucket Username"
                    value={gitServiceDetail?.username}
                    onChange={(event: any) =>
                      setGitServiceDetail({
                        ...gitServiceDetail,
                        username: event?.currentTarget?.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: "16px",
                  }}
                >
                  <TextInput
                    label="Bitbucket Password"
                    value={gitServiceDetail?.password}
                    onChange={(event: any) =>
                      setGitServiceDetail({
                        ...gitServiceDetail,
                        password: event?.currentTarget?.value,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
