import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mantine/core";
import ClientDetail from "../../components/clients/clientDetail";
import RepositoryDetail from "../../components/clients/repositoryDetail";
import InitialEnvironment from "../../components/clients/initialEnvironment";
import { AuthContext } from "../../contexts/authContext";
import userServices from "../../services/userServices";
import { IGitService, IRepository, IEnvironmentDetail } from "../../models";
import MainLayout from "../../layouts/mainlayouts";

export default function UpdateClient() {
  const { id } = useParams();
  const [clientName, setClientName] = useState("");
  const [gitServiceDetail, setGitServiceDetail] = useState<IGitService>({});
  const [repository, setRepository] = useState<IRepository>();
  const [progress, setProgress] = useState(0);
  const [initEnv, setInitEnv] = useState<IEnvironmentDetail>({
    designateProd: "Yes",
  });
  const [clientId, setClientId] = useState(-1);
  const [repoId, setRepoId] = useState(-1);
  const [envId, setEnvId] = useState(-1);
  const [clientDetailDisable, setClientDetailDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  function capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const getClients = async () => {
    try {
      setErrorMessage("")
      const [
        clientDetail,
        repoDetail,
        fusionDetail
      ] = await Promise.all([
        userServices.getClientDetail(id),
        userServices.getClientRepositories(id),
        userServices.getFusionEnvironment(id),
      ]);
      if (clientDetail.status === 200) {
        setClientId(clientDetail?.data?.id)
        setClientName(clientDetail?.data?.clientName)
      } else if (clientDetail.status !== 200) {
        setErrorMessage(clientDetail.data.result)
      }

      if (repoDetail.status === 200) {
        setRepository({
          name: repoDetail?.data[0].name,
          url: repoDetail?.data[0].url
        })
        setGitServiceDetail({
          username: repoDetail?.data[0].username,
          serviceType: capitalizeFirstLetter(repoDetail?.data[0].serverType),
          authType: capitalizeFirstLetter(repoDetail?.data[0].authType) + " Auth",
        })
        setRepoId(repoDetail?.data[0].id)
      } else if (repoDetail.status !== 200) {
        setErrorMessage(repoDetail.data.result)
      }

      if (fusionDetail.status === 200) {
        setInitEnv({
          envName: fusionDetail?.data[0].name,
          designateProd: "Yes",
          url: fusionDetail?.data[0].host,
          port: fusionDetail?.data[0].port,
          ssl: fusionDetail?.data[0].ssl,
        })
        setEnvId(fusionDetail?.data[0].id)
      } else if (fusionDetail.status !== 200) {
        setErrorMessage(fusionDetail.data.result)
      }
    } catch (err: any) {
      setErrorMessage(err)
    }
  };

  useEffect(() => {
    getClients()
  }, [])

  const cancelClicked = () => {
    navigate("/dashboard");
  };

  const continueClicked = async () => {
    if (progress === 0) {
      setClientDetailDisable(false);
      setProgress(1);
    } else if (progress === 1) {
      try {
        const result = await userServices.updateClientRepositories(
          repository?.name ? repository?.name : "",
          repository?.url ? repository?.url : "",
          gitServiceDetail?.serviceType
            ? gitServiceDetail?.serviceType.toLocaleLowerCase()
            : "",
          gitServiceDetail?.authType
            ? gitServiceDetail?.authType.toLocaleLowerCase().split(" ")[0]
            : "",
          gitServiceDetail?.password ? gitServiceDetail?.password : "",
          gitServiceDetail?.username ? gitServiceDetail?.username : "",
          gitServiceDetail?.gitToken ? gitServiceDetail?.gitToken : "",
          clientId ? clientId : "",
          repoId ? repoId : "",
        );

        if (result.status === 200) {
          setClientDetailDisable(false);
          setErrorMessage('')
          setProgress(2);
        }
      } catch (error: any) {
        setClientDetailDisable(true);
        setErrorMessage('Repository creation failed. Please try again. Thanks')
        setProgress(1);
      }
    } else if (progress === 2) {
      try {
        const result = await userServices.updateFusionEnvironment(
          clientId,
          envId,
          initEnv?.envName ? initEnv?.envName : "",
          initEnv?.url ? initEnv?.url : "",
          initEnv?.port ? initEnv?.port : "",
          "password",
          initEnv?.envUsername ? initEnv?.envUsername : "",
          initEnv?.envPassword ? initEnv?.envPassword : "",
          "",
          initEnv?.ssl ? initEnv?.ssl : true
        );

        if (result.status === 200) {
          setClientDetailDisable(false);
          setErrorMessage('')
          navigate("/dashboard");
        } else {
          setClientDetailDisable(true);
          setErrorMessage('Fusion environment creation failed. Please try again. Thanks')
          setProgress(2);
        }
        
      } catch (error: any) {
        setClientDetailDisable(true);
        setErrorMessage('Fusion environment creation failed. Please try again. Thanks')
        setProgress(2);
      }
    }
  };

  const clientDisableHandle = () => {
    if (progress === 0) {
      setClientDetailDisable(
        clientName !== "" && Object.keys(gitServiceDetail).length === 4
          ? true
          : false
      );
    } else if (progress === 1) {
      setClientDetailDisable(
        repository && Object.values(repository) ? true : false
      );
    } else if (progress === 2) {
      setClientDetailDisable(
        initEnv?.envName !== undefined &&
        initEnv?.envPassword !== undefined &&
        initEnv?.envName !== undefined &&
        initEnv?.url !== undefined
      );
    }
  };

  useEffect(() => {
    clientDisableHandle();
  }, [clientName, gitServiceDetail, repository, initEnv]);

  return (
    <MainLayout
      pageComponent={
        <>
          <div
            style={{
              marginTop: "64px",
              fontSize: "32px",
              fontWeight: "600",
            }}
          >
            Edit a client detail
          </div>
          {progress === 0 ? (
            <ClientDetail
              clientName={clientName}
              setClientName={setClientName}
              gitServiceDetail={gitServiceDetail}
              setGitServiceDetail={setGitServiceDetail}
            />
          ) : progress === 1 ? (
            <RepositoryDetail
              repository={repository}
              setRepository={setRepository}
              gitServiceDetail={gitServiceDetail}
              setErrorMessage={setErrorMessage}
              setProgress={setProgress}
            />
          ) : progress === 2 ? (
            <InitialEnvironment initEnv={initEnv} setInitEnv={setInitEnv} />
          ) : (
            <></>
          )}
          <p style={{color: 'red'}}>{errorMessage}</p>
          <div
            style={{
              marginTop: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "36%",
            }}
          >
            
            <div>
              <Button
                radius="md"
                onClick={() => continueClicked()}
                disabled={!clientDetailDisable}
              >
                {progress === 2 ? "Finish" : "Continue"}
              </Button>
            </div>
            <div>
              <Button radius="md" onClick={() => cancelClicked()}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      }
    />
  );
}
