import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext";
import { Button, Table, Tooltip } from "@mantine/core";
import userServices from "../../services/userServices";
import MainLayout from "../../layouts/mainlayouts";
import MenuBar from "../../components/menuBar";
import { Loader } from '@mantine/core';
import { IconEdit } from "@tabler/icons-react";
import CreateUser from "../../components/users/createUser";
import EditUser from "../../components/users/editUser";
import { IUser, IUserInput } from "../../models";
import { toastError } from "../../utils/toasts";

export default function Settings() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate()
  const [selected, setSelected] = useState("Settings");
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [userAdd, setUserAdd] = useState(false)
  const [userEdit, setUserEdit] = useState(false)
  const [userId, setUserId] = useState(-1)
  const [userDetail, setUserDetail] = useState<IUserInput>({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordConfirm: "",
  })
  const [users, setUsers] = useState<IUser[]>([])

  const addUserHandle = () => {
    setUserAdd(true);
  }

  const editUserHandle = (id: number) => {
    setUserEdit(true)
    setUserId(id)
  }

  const getUserDetails = async () => {
    try {
      setErrorMessage("")
      setLoading(true)
      const users = await userServices.getUsers();
      if (users.status === 200) {
        const tmpUsers:IUser[] = []
        users?.data.map((_item: any, index: number) => {
          const tmp: IUser = {
            id: _item.id,
            username: _item.username,
            firstname: _item.firstName,
            lastname: _item.lastName,
            email: _item.email,
            active: _item.active
          }
          tmpUsers.push(tmp)
          return
        })
        setUsers(tmpUsers)
        setLoading(false)
      } else if (users.status === 403) {
        authContext.signOut();
        navigate("/");
      } else {
        setLoading(false)
        toastError(users.data.error)
      }
    } catch (err: any) {
      setErrorMessage(err)
      setLoading(false)
      toastError("Something went wrong. Please refresh the page and try again")
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [userAdd, userEdit]);

  const rows = users?.map((element: IUser, index: number) => (
    <tr key={element.id}>
      <td>{element.username}</td>
      <td>{element.firstname}</td>
      <td>{element.lastname}</td>
      <td>{element.email}</td>
      <td>{element.active ? "Active" : "Disabled"}</td>
      <td style={{textAlign: "center"}}>
        <Tooltip label="Edit User">
          <Button variant="outline" onClick={() => editUserHandle(element.id)} style={{padding: "4px"}}>
            <IconEdit/>
          </Button>
        </Tooltip>
      </td>
    </tr>
  ));

  return (
    <MainLayout
      pageComponent={
        !loading ? 
        <>
          <MenuBar selected={selected} setSelected={setSelected} />
          <div 
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              backgroundColor: "#FFFFFF",
              minHeight: "100vh",
              borderRadius: "10px",
              marginTop: "30px",
              boxShadow: "2",
              position: "relative"
            }}
          >
            {
            userAdd ? 
              <CreateUser 
                userDetail={userDetail} 
                setUserDetail={setUserDetail}
                setUserAdd={setUserAdd}
              />
              :
              userEdit ? 
              <EditUser
                userDetail={userDetail} 
                setUserDetail={setUserDetail}
                setUserEdit={setUserEdit}
                userId={userId}
              />
              :
              <>
                <div style={{
                  position: "absolute",
                  right: "16px",
                  top: "16px"
                }}>
                  <Button onClick={() => addUserHandle()}>Add New User</Button>
                </div>
                {users?.length > 0 ? (
                  <div
                    style={{
                      marginTop: "50px",
                      width: "100%",
                      padding: "20px"
                    }}
                  >
                    <Table striped highlightOnHover withBorder>
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Firstname</th>
                          <th>Lastname</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th style={{textAlign: "center"}}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </Table>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        marginTop: "50px",
                        width: "100%",
                        padding: "20px"
                      }}
                    >
                      <Table striped highlightOnHover withBorder>
                        <thead>
                          <tr>
                            <th>Username</th>
                            <th>Firstname</th>
                            <th>Lastname</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th style={{textAlign: "center"}}>Actions</th>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </>)
                }
              </>
            } 
          </div>
        </>
        :
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: '100%'}}><Loader size={50} /></div>
      }
    />
  );
}
