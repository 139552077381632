/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mantine/core";
import ClientDetail from "../../components/clients/clientDetail";
import RepositoryDetail from "../../components/clients/repositoryDetail";
import InitialEnvironment from "../../components/clients/initialEnvironment";
import { AuthContext } from "../../contexts/authContext";
import userServices from "../../services/userServices";
import { IGitService, IRepository, IEnvironmentDetail } from "../../models";
import MainLayout from "../../layouts/mainlayouts";

export default function CreateClient() {
  const [clientName, setClientName] = useState("");
  const [gitServiceDetail, setGitServiceDetail] = useState<IGitService>({});
  const [repository, setRepository] = useState<IRepository>();
  const [progress, setProgress] = useState(0);
  const [initEnv, setInitEnv] = useState<IEnvironmentDetail>({
    designateProd: "Yes",
  });
  const [clientId, setClientId] = useState(-1);
  const [repoId, setRepoId] = useState(-1);
  const [clientDetailDisable, setClientDetailDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const cancelClicked = () => {
    navigate("/dashboard");
  };

  const continueClicked = async () => {
    if (progress === 0) {
      try {
        setErrorMessage("")
        if (clientId === -1) {
          const result = await userServices.createClient(clientName);
          if (result.status === 200) {
            setClientId(result?.data?.id);
            setClientDetailDisable(false);
            setErrorMessage('')
            setProgress(1);
          } else if (result.status === 422 && result?.data?.result === 'Client already exists') {
            setErrorMessage('Client is already existed. Please try another one. Thanks')
            setClientDetailDisable(true);
            setProgress(0);
          }
        } else {
          setClientDetailDisable(false);
          setErrorMessage('')
          setProgress(1);
        }
      } catch (error: any) {
        setClientDetailDisable(true);
        setErrorMessage('Client creation failed. Please try again later. Thanks')
        setProgress(0);
      }
    } else if (progress === 1) {
      try {
        setErrorMessage("")
        const result = await userServices.createRepository(
          repository?.name ? repository?.name : "",
          repository?.url ? repository?.url : "",
          gitServiceDetail?.serviceType
            ? gitServiceDetail?.serviceType.toLocaleLowerCase()
            : "",
          gitServiceDetail?.authType
            ? gitServiceDetail?.authType.toLocaleLowerCase().split(" ")[0]
            : "",
          gitServiceDetail?.password ? gitServiceDetail?.password : "",
          gitServiceDetail?.username ? gitServiceDetail?.username : "",
          gitServiceDetail?.gitToken ? gitServiceDetail?.gitToken : "",
          clientId ? clientId : ""
        );

        if (result.status === 200) {
          setRepoId(result?.data?.id);
          setClientDetailDisable(false);
          setErrorMessage('')
          setProgress(2);  
        } else {
          setClientDetailDisable(true);
          setErrorMessage(result.data.result)
          setProgress(1);
        }
      } catch (error: any) {
        setClientDetailDisable(true);
        setErrorMessage('Repository creation failed. Please try again. Thanks')
        setProgress(1);
      }
    } else if (progress === 2) {
      try {
        setErrorMessage("")
        const result = await userServices.createFusionEnvironment(
          initEnv?.envName ? initEnv?.envName : "",
          initEnv?.url ? initEnv?.url : "",
          initEnv?.port ? initEnv?.port : "",
          "password",
          initEnv?.envUsername ? initEnv?.envUsername : "",
          initEnv?.envPassword ? initEnv?.envPassword : "",
          "",
          clientId,
          initEnv?.ssl ? initEnv?.ssl : true,
          true
        );

        if (result.status === 200) {
          setClientDetailDisable(false);
          setErrorMessage('')
          navigate("/dashboard");
        } else {
          setClientDetailDisable(true);
          setErrorMessage(result.data.result)
          setProgress(2);
        }
        
      } catch (error: any) {
        setClientDetailDisable(true);
        setErrorMessage('Fusion environment creation failed. Please try again. Thanks')
        setProgress(2);
      }
    }
  };

  const clientDisableHandle = () => {
    if (progress === 0) {
      setClientDetailDisable(
        clientName !== "" && Object.keys(gitServiceDetail).length === 4
          ? true
          : false
      );
    } else if (progress === 1) {
      setClientDetailDisable(
        repository && Object.values(repository) ? true : false
      );
    } else if (progress === 2) {
      setClientDetailDisable(
        initEnv?.envName !== undefined &&
          initEnv?.envPassword !== undefined &&
          initEnv?.envName !== undefined &&
          initEnv?.url !== undefined
      );
    }
  };

  useEffect(() => {
    clientDisableHandle();
  }, [clientName, gitServiceDetail, repository, initEnv]);

  return (
    <MainLayout
      pageComponent={
        <>
          <div
            style={{
              marginTop: "64px",
              fontSize: "32px",
              fontWeight: "600",
            }}
          >
            Create a New Client
          </div>
          {progress === 0 ? (
            <ClientDetail
              clientName={clientName}
              setClientName={setClientName}
              gitServiceDetail={gitServiceDetail}
              setGitServiceDetail={setGitServiceDetail}
            />
          ) : progress === 1 ? (
            <RepositoryDetail
              repository={repository}
              setRepository={setRepository}
              gitServiceDetail={gitServiceDetail}
              setErrorMessage={setErrorMessage}
              setProgress={setProgress}
            />
          ) : progress === 2 ? (
            <InitialEnvironment initEnv={initEnv} setInitEnv={setInitEnv} />
          ) : (
            <></>
          )}
          <p style={{color: 'red'}}>{errorMessage}</p>
          <div
            style={{
              marginTop: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "36%",
            }}
          >
            
            <div>
              <Button
                radius="md"
                onClick={() => continueClicked()}
                disabled={!clientDetailDisable}
              >
                {progress === 2 ? "Finish" : "Continue"}
              </Button>
            </div>
            <div>
              <Button radius="md" onClick={() => cancelClicked()}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      }
      />
  );
}
