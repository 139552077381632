import { useContext } from "react";
import "./App.css";
import { SignInRoute, MainRoute } from "./routes";
import { MantineProvider } from "@mantine/core";
import { ThemeContext } from "./contexts/themeContext";
import { ToastContainer } from "react-toastify";
import AuthProvider, {
  AuthIsSignedIn,
  AuthIsNotSignedIn,
} from "./contexts/authContext";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { status } = useContext(ThemeContext);
  return (
    <MantineProvider
      theme={{ colorScheme: status ? "dark" : "light" }}
      withGlobalStyles
      withNormalizeCSS
    >
      <AuthProvider>
        <AuthIsSignedIn>
          <MainRoute />
        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
          <SignInRoute />
        </AuthIsNotSignedIn>
      </AuthProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme={status ? "dark" : "light"}
      />
    </MantineProvider>
  );
}

export default App;
