import React from "react";
import { Progress, PasswordInput, Group, TextInput } from "@mantine/core";

const requirements = [
  { re: /[0-9]/, label: "" },
  { re: /[a-z]/, label: "" },
  { re: /[A-Z]/, label: "" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "" },
];

function getStrength(password: string) {
  let multiplier = password.length >= 10 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

export function PasswordStrength({
  label,
  password,
  setPassword,
}: {
  label: string;
  password: string;
  setPassword: (_: string) => void;
}) {
  const strength = getStrength(password);
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ bar: { transitionDuration: "0ms" } }}
        value={
          password.length >= 10 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <div style={{ width: "100%" }}>
      <PasswordInput
        value={password}
        onChange={handleChange}
        label={label}
        required
      />
      <Group spacing={5} grow mt="xs" mb="md">
        {bars}
      </Group>
    </div>
  );
}

export function UsernameStrength({
  username,
  setUsername,
}: {
  username: string;
  setUsername: (_: string) => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  return (
    <div style={{ width: "100%" }}>
      <TextInput
        value={username}
        onChange={handleChange}
        label="Enter your user name"
        withAsterisk
      />
    </div>
  );
}

export function CodeStrength({
  code,
  setCode,
}: {
  code: string;
  setCode: (_: string) => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  return (
    <div style={{ width: "100%" }}>
      <TextInput
        value={code}
        onChange={handleChange}
        label="Verification code"
        withAsterisk
      />
    </div>
  );
}

export function Password({
  label,
  password,
  setPassword,
}: {
  label: string;
  password: string;
  setPassword: (_: string) => void;
}) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <div style={{ width: "100%" }}>
      <PasswordInput
        value={password}
        onChange={handleChange}
        label={label}
        required
      />
    </div>
  );
}
