import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURI;

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    accessToken?: string;
  };
  authStatus?: AuthStatus;
  signInWithUsername?: any;
  signOut?: any;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

type Props = {
  children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const access_token = sessionStorage.getItem("accessToken");
        if (access_token) {
          setSessionInfo({
            accessToken: access_token,
          });

          setAuthStatus(AuthStatus.SignedIn);
        } else {
          signOut();
        }
      } catch (err) {
        sessionStorage.removeItem("accessToken");
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();
  }, [setAuthStatus, authStatus]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithUsername(username: string, password: string) {
    return axios
      .post(API_URL + "login", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.access_token) {
          setAuthStatus(AuthStatus.SignedIn);
          sessionStorage.setItem("accessToken", JSON.stringify(response.data));
        }
        return response;
      }).catch((error) => {
        return error.response
      });
  }

  function signOut() {
    sessionStorage.removeItem("accessToken");
    setAuthStatus(AuthStatus.SignedOut);
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    signInWithUsername,
    signOut,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
