import { toast } from 'react-toastify'

export function toastSuccess(successText: string) {
  toast(
    <span style={{ fontSize: '0.75rem' }}>
      <p style={{ color: '#07bc0c' }}>Success!</p>
      <p>{successText}</p>
    </span>,
    { type: 'success' }
  )
}

export function toastError(errorText: string) {
  toast(
    <span style={{ fontSize: '0.75rem' }}>
      <p style={{ color: '#e74c3c' }}>There was a problem</p>
      <p>{errorText}</p>
    </span>,
    { type: 'error' }
  )
}
