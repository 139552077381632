/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState} from "react";
import { Table, Button, Tooltip, Modal, ScrollArea, Grid } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { IPromotions } from "../../models";
import { IconInfoSquareRoundedFilled, IconWindowMaximize, IconWindowMinimize, IconPlayerPlay } from "@tabler/icons-react";
import userServices from "../../services/userServices";
import gitIcon from "../../assets/git.png"
import { toastSuccess, toastError } from "../../utils/toasts";


export default function PromotionTable({ promotions }: { promotions: IPromotions[] }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [content, setContent] = useState<IPromotions>()
  const [windowStatus, setWindowStatus] = useState(false)

  const openHandler = (index: number) => {
    open()
    setContent(promotions[index])
    setWindowStatus(false)
  }

  const closeHandler = () => {
    close()
    setContent(undefined)
    setWindowStatus(false)
  }

  const startDeployment = async (id: number) => {
    try {
      const deploymentStatus = await userServices.startDeployment(
        id
      )
      if (deploymentStatus.status === 200) {
        toastSuccess('Deployment Started Successfully.')
      } else {
        toastError('Deployment Failed. Please try again or check system logs.')
      }
    } catch(error: any) {
      toastError('Deployment Failed. Please try again or check system logs.')
    }
  }

  const modalContent = content ?
    <>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"id"}</Grid.Col>
        <Grid.Col span={8}>{content.id}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"clientId"}</Grid.Col>
        <Grid.Col span={8}>{content.clientId}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"clientName"}</Grid.Col>
        <Grid.Col span={8}>{content.clientName}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"userId"}</Grid.Col>
        <Grid.Col span={8}>{content.userId}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"sourceEnvironmentName"}</Grid.Col>
        <Grid.Col span={8}>{content.sourceEnvironmentName}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"targetEnvironmentName"}</Grid.Col>
        <Grid.Col span={8}>{content.targetEnvironmentName}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"status"}</Grid.Col>
        <Grid.Col span={8}>{content.status}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"message"}</Grid.Col>
        <Grid.Col span={8}>{content.message}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"history"}</Grid.Col>
        <Grid.Col span={8}>
          {
            content.history?.split(",").map((item: string, index: number) => (
              <div key={index}>{"- " + item}</div>
            ))
          }
        </Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"created_at"}</Grid.Col>
        <Grid.Col span={8}>{content.created_at}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"prLink"}</Grid.Col>
        <Grid.Col span={8}>{content.prLink}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"promotionTitle"}</Grid.Col>
        <Grid.Col span={8}>{content.promotionTitle}</Grid.Col>
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
        <Grid.Col span={4} style={{ fontWeight: "bold" }}>{"promotionDescription"}</Grid.Col>
        <Grid.Col span={8}>{content.promotionDescription}</Grid.Col>
      </Grid>
    </>
    :
    <></>

  const rows = promotions?.map((element: IPromotions, index: number) => (
    <tr key={element.id}>
      <td>{element.clientName}</td>
      <td>{element.promotionTitle}</td>
      <td>{element.sourceEnvironmentName}</td>
      <td>{element.targetEnvironmentName}</td>
      <td>{element.status}</td>
      <td>
        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
        {element.status === "PendingDeployment" && (
          <a>
            <Tooltip label="Deploy Changes">
              <Button variant="outline" style={{ padding: '4px' }} onClick={() => startDeployment(element.id)}>
                <IconPlayerPlay />
              </Button>
            </Tooltip>
          </a>
          )}
          <a>
            <Tooltip label="View Detail">
              <Button variant="outline" style={{ padding: '4px' }} onClick={() => openHandler(index)}>
                <IconInfoSquareRoundedFilled />
              </Button>
            </Tooltip>
          </a>
          <a href={element.prLink} target="_blank" rel="noreferrer">
            <Tooltip label="View PR">
              <Button variant="outline" style={{ padding: '4px' }}>
                <img src={gitIcon} style={{ width: "24px" }}  alt=""/>
              </Button>
            </Tooltip>
          </a>
        </div>
      </td>
    </tr>
  ));

  return (
    <>
      <Modal.Root
        opened={opened}
        onClose={closeHandler}
        scrollAreaComponent={ScrollArea.Autosize}
        centered
        fullScreen={windowStatus}
        radius={10}
        size="50%"
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Promotion Detail</Modal.Title>
            <div style={{ display: "flex", alignItems: " center", gap: "8px" }}>
              <Button
                p={0}
                variant="transparent"
                onClick={() => setWindowStatus(!windowStatus)}
              >
                {
                  !windowStatus
                    ?
                    <IconWindowMaximize style={{ color: "gray", width: "20px" }} />
                    :
                    <IconWindowMinimize style={{ color: "gray", width: "20px" }} />
                }
              </Button>
              <Modal.CloseButton />
            </div>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
        </Modal.Content>
      </Modal.Root>
      {promotions?.length > 0 ? (
        <div
          style={{
            width: "100%",
            padding: "20px"
          }}
        >
          <Table striped highlightOnHover withBorder style={{ marginTop: "16px" }}>
            <thead>
              <tr>
                <th>Client</th>
                <th>Title</th>
                <th>Source Environment</th>
                <th>Target Environment</th>
                <th>Status</th>
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              padding: "20px"
            }}
          >
            <Table striped highlightOnHover withBorder style={{ marginTop: "16px" }}>
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Source Environment</th>
                  <th>Target Environment</th>
                  <th>Status</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
            </Table>
          </div>
        </>)
      }
    </>
  );
}
